/* line 26, scss/80-themes/Saunders/60-components/article-media.scss */
.c-article-media {
	margin-bottom: 20px;
	line-height: 1;
	position: relative;
	height: 100%;
}

/* line 32, scss/80-themes/Saunders/60-components/article-media.scss */
.c-article-media .empty {
	display: none;
}

/* line 36, scss/80-themes/Saunders/60-components/article-media.scss */
.c-article-media [data-tab-contents-role] {
	height: 100%;
	position: relative;
	z-index: 1;
}

/* line 42, scss/80-themes/Saunders/60-components/article-media.scss */
.c-article-media .o-button {
	background-clip: padding-box;
	background-color: transparent;
	border-color: rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-secondary));
}

/* line 48, scss/80-themes/Saunders/60-components/article-media.scss */
.c-article-media .o-button:hover {
	border-color: rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-secondary));
}

/* line 53, scss/80-themes/Saunders/60-components/article-media.scss */
.c-article-media .o-button.selected {
	background-color: rgb(var(--color-global-secondary));
	border-color: rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-positive));
}

/* line 60, scss/80-themes/Saunders/60-components/article-media.scss */
.c-article-media .tabs__content {
	display: block;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

/* line 70, scss/80-themes/Saunders/60-components/article-media.scss */
.c-article-media .tabs__toggle:checked + .tabs__content {
	opacity: 1;
	z-index: 3;
}

/* line 76, scss/80-themes/Saunders/60-components/article-media.scss */
.article-media-container-overlay.article-media-media__controls {
	z-index: 3;
	left: auto;
	right: 10px;
	position: absolute;
	background-color: rgba(var(--color-global-positive), 0.8);
	padding: 10px;
	bottom: 10px;
	height: auto;
	box-sizing: border-box;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .article-media-container-overlay.article-media-media__controls {
	left: 10px;
	right: auto;
}

@media only screen and (max-width: 47.999em) {
	/* line 76, scss/80-themes/Saunders/60-components/article-media.scss */
	.article-media-container-overlay.article-media-media__controls {
		left: 0;
		right: 0;
		bottom: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .article-media-container-overlay.article-media-media__controls {
		left: 0;
		right: 0;
	}
}

/* line 92, scss/80-themes/Saunders/60-components/article-media.scss */
.article-media-photo-carousel-controls {
	text-align: right;
	margin-left: 20px;
	user-select: none;
	color: rgb(var(--color-global-secondary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .article-media-photo-carousel-controls {
	text-align: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .article-media-photo-carousel-controls {
	margin-left: initial;
	margin-right: 20px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .article-media-photo-carousel-controls {
		margin-left: 0;
	}
}

/* line 98, scss/80-themes/Saunders/60-components/article-media.scss */
.article-media-photo-carousel-controls .disabled {
	color: rgb(var(--c-article-media-controls-disabled-color));
}

/* line 102, scss/80-themes/Saunders/60-components/article-media.scss */
.article-media-photo-carousel-controls .photo-count {
	margin-left: 10px;
	color: rgb(var(--color-global-text));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .article-media-photo-carousel-controls .photo-count {
	margin-left: initial;
	margin-right: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .article-media-photo-carousel-controls .photo-count {
		margin-left: 0;
	}
}

/* line 108, scss/80-themes/Saunders/60-components/article-media.scss */
.article-media-photo-carousel-control {
	margin: 0 5px;
}

@media only screen and (max-width: 47.999em) {
	/* line 108, scss/80-themes/Saunders/60-components/article-media.scss */
	.article-media-photo-carousel-control {
		font-size: 1.2em;
	}
}

/* line 116, scss/80-themes/Saunders/60-components/article-media.scss */
.article-media-photo-carousel {
	height: 100%;
}

/* line 122, scss/80-themes/Saunders/60-components/article-media.scss */
.article-media__tabs {
	margin-left: 5px;
	border-width: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .article-media__tabs {
	margin-left: initial;
	margin-right: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .article-media__tabs {
		margin-left: 0;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 122, scss/80-themes/Saunders/60-components/article-media.scss */
	.article-media__tabs {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

/* line 135, scss/80-themes/Saunders/60-components/article-media.scss */
.article-media__tabs-container {
	display: inline-block;
}

@media only screen and (max-width: 47.999em) {
	/* line 140, scss/80-themes/Saunders/60-components/article-media.scss */
	.article-media-container-overlay.article-media-media__controls .article-media__tab {
		padding: 5px;
	}
}

/*# sourceMappingURL=../../../../true */